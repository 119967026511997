<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Code"
              v-model="form.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Owner</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Owner"
              v-model="form.owner"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Name"
              v-model="form.name"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Contract Number"
              v-model="form.contractNumber"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Contract Number"
              v-model="form.status"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Leader</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Leader"
              v-model="form.commander"
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Title</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Title"
              v-model="filter.title"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-success btn-md mr-2" @click="onResetData">
            <i class="flaticon2-refresh"></i> Reset
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs content-class="mt-3" v-model="selectedTabName">
          <b-tab title="Document">
            <document
              :project="form"
              ref="docRef"
              :dataFilter="filter"
            ></document>
          </b-tab>
          <b-tab title="Drawing">
            <drawing
              :project="form"
              ref="drwRef"
              :dataFilter="filter"
            ></drawing>
          </b-tab>
          <b-tab title="Software">
            <software
              :project="form"
              ref="softRef"
              :dataFilter="filter"
            ></software>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Document from "./edit-components/Document";
import Software from "./edit-components/Form";
import Drawing from "./edit-components/Drawing";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_PROJECT_DETAIL_BY_ID } from "@/core/services/store/project.module";
import { mapGetters } from "vuex";

export default {
  name: "EditCheckListDistribution",
  components: {
    Document,
    Drawing,
    Software,
  },

  data() {
    return {
      selectedTabName: "",
      form: {
        status: "",
        name: "",
        code: "",
        owner: "",
        contractNumber: "",
        commander: null,
      },
      filter: {
        status: null,
        name: null,
        code: null,
        owner: null,
        contractNumber: null,
        nickname: null,
        station: null,
        scope: null,
        title: null,
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN",
          },
        ],
      },
    };
  },
  methods: {
    mappingDataProject(data) {
      Object.assign(this.form, {
        status: data.project.status,
        name: data.project.name,
        code: data.project.code,
        owner: data.project.owner,
        contractNumber: data.project.contract_no,
        commander: data.project.leader,
        nickname: data.project.nickname,
      });
    },

    onResetData() {
      Object.assign(this.filter, {
        title: null,
      });

      if (this.selectedTabName === 0) {
        this.$refs.docRef.doReset();
      } else if (this.selectedTabName === 1) {
        this.$refs.drwRef.doReset();
      } else if (this.selectedTabName === 2) {
        this.$refs.softRef.doReset();
      }
    },
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Checklist Distribution" }]);
    this.$store
      .dispatch(GET_PROJECT_DETAIL_BY_ID, this.$route.params.id)
      .then(() => this.mappingDataProject(this.currentProjectDetail));
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentProjectDetail",
      "projectStation",
      "projectDocon",
      "projectCompany",
    ]),
  },
  watch: {
    $route(to) {
      this.$store
        .dispatch(GET_PROJECT_DETAIL_BY_ID, to.params)
        .then(() => this.mappingDataProject(this.currentProjectDetail));
    },
  },
};
</script>
