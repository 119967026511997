<template>
  <div>
    <div class="d-flex justify-content-end mt-3">
      <template v-if="this.data.dld.length > 0">
        <input
          :id="'fileImportExcel'"
          @input="onFileChange($event)"
          type="file"
          hidden
        />

        <a
          class="btn btn-light-warning font-weight-bolder font-size-sm mr-2"
          @click="importData"
          :disabled="loadingDesign"
          ref="kt_import_data"
        >
          <i class="fa fa-file-excel"></i>
          Import
        </a>

        <vue-excel-xlsx
          :data="data.exportList"
          :columns="columns"
          :file-name="`${this.project.code}-${this.project.nickname}-checlist distiribution document`"
          :sheetname="'sheet1'"
        >
          <button
            class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
            :disabled="data.exportList.length === 0"
          >
            <i class="fa fa-file-excel"></i>
            Export
          </button>
        </vue-excel-xlsx>
      </template>

      <button
        class="btn btn-success font-weight-bolder font-size-sm mr-2"
        @click="onSave"
        :disabled="data.dld.length === 0"
      >
        <i class="fa fa-save" />Save
      </button>

      <button
        class="btn btn-info font-weight-bolder font-size-sm mr-2"
        @click="onLoadDesign"
      >
        <i class="fa fa-sync" />Load Design
      </button>
    </div>

    <div class="table-responsive mt-3">
      <complete-table :loading="loadingDesign" :data="filteredDesign">
        <template #header>
          <tr class="text-left">
            <th style="min-width: 50px" class="pl-7">
              <span class="text-dark-75">No.</span>
            </th>
            <th style="min-width: 200px" class="pl-7">
              <span class="text-dark-75">Numbering</span>
            </th>
            <th style="min-width: 100px">Title</th>
            <th style="min-width: 100px">Station</th>
            <th style="min-width: 100px">Type</th>
            <th style="min-width: 100px">Category</th>
            <th style="min-width: 80px">Scope</th>
            <th style="min-width: 50px">Internal</th>
            <th style="min-width: 50px">SHD External</th>
          </tr>
        </template>

        <template #defaultBody="{ item, i, number }">
          <tr v-bind:key="i">
            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ number }}
              </span>
            </td>

            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.numbering }}
              </span>
            </td>

            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.title }}
              </span>
            </td>

            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.station }}
              </span>
            </td>

            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.type }}
              </span>
            </td>

            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.category }}
              </span>
            </td>

            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.scope }}
              </span>
            </td>

            <td class="pl-0">
              <b-form-checkbox
                v-model="item.monitoring_logistic_status"
                value="1"
                unchecked-value="0"
              ></b-form-checkbox>
            </td>

            <td class="pl-0">
              <b-form-checkbox
                v-model="item.monitoring_shd_external_status"
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox>
            </td>
          </tr>
        </template>
      </complete-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_CHECKLIST_DISTRIBUTION_DOC,
  CLEAN_STATE,
  POST_CHECKLIST_DISTRIBUTION,
} from "@/core/services/store/distribution.module.js";
import CompleteTable from "../../../../content/widgets/advance-table/CompleteTable.vue";
import readXlsxFile from "read-excel-file";
//let steps = ['id', 'numbering', 'title', 'type', 'scope', 'station'];
export default {
  name: "DocumentChecklist",
  components: {
    CompleteTable,
  },
  props: {
    project: {
      required: true,
    },
    dataFilter: {
      required: true,
    },
  },
  data() {
    return {
      opened: [],
      currentIndex: null,
      oldData: {},
      data: {
        dld: [],
        projects: [],
        exportList: [],
      },
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 5,
      },
      search: "",
      pageOfItems: [],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Scope",
          field: "scope",
        },
        {
          label: "Station",
          field: "station",
        },
        {
          label: "Internal",
          field: "monitoring_logistic_status",
        },
        {
          label: "SHD External",
          field: "monitoring_shd_external_status",
        },
        {
          label: "Category",
          field: "category",
        },
      ],
    };
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        console.log("rows:", rows);
        this.onImportData(rows);
      });
    },

    doReset() {
      this.onLoadDesign();
    },

    onImportData(listData) {
      let dataSubmit = [];
      console.log("listData:", listData);
      for (let i = 1; i < listData.length; i++) {
        dataSubmit.push({
          id: listData[i][0],
          numbering: listData[i][1],
          title: listData[i][2],
          monitoring_logistic_status: listData[i][6],
          monitoring_shd_external_status: listData[i][7],
        });
      }

      const submitButton = this.$refs["kt_import_data"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      setTimeout(() => {
        let data = {
          dld: dataSubmit,
          project: this.project,
        };
        this.$store
          .dispatch(POST_CHECKLIST_DISTRIBUTION, data)
          .then(() => this.onLoadDesign());

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    importData() {
      document.getElementById("fileImportExcel").click();
    },

    onSave() {
      let data = {
        dld: this.data.dld,
        project: this.project,
      };
      this.$store.dispatch(POST_CHECKLIST_DISTRIBUTION, data);
    },

    loadDesign(projectID) {
      this.data.dld = [];
      this.$store
        .dispatch(GET_CHECKLIST_DISTRIBUTION_DOC, {
          project_id: projectID,
        })
        .then(() => this.mappingDataObject(this.currentData));
    },

    cleanDocument() {
      this.$store.dispatch(CLEAN_STATE, "DOC");
    },

    mappingDataObject(listData) {
      listData.map((data) => {
        this.data.dld.push({
          id: data.id,
          numbering: data.numbering,
          title: data.title,
          type: data.type ? data.type.name : null,
          scope: data.scope ? data.scope.name : null,
          station: data.station ? data.station.name : null,
          category: data.category ? data.category.name : null,
          monitoring_logistic_status: data.monitoring_logistic_status,
          monitoring_shd_external_status: data.monitoring_shd_external_status,
          monitoring_asbuilt_status: data.monitoring_asbuilt_status,
          monitoring_change_management_status:
            data.monitoring_change_management_status,
          monitoring_floor_lrs_status: data.monitoring_floor_lrs_status,
          monitoring_dl_design_len_status: data.monitoring_dl_design_len_status,
        });

        this.data.exportList.push({
          id: data.id,
          numbering: data.numbering,
          title: data.title,
          type: data.type ? data.type.name : null,
          scope: data.scope ? data.scope.name : null,
          station: data.station ? data.station.name : null,
          category: data.category ? data.category.name : null,
          monitoring_logistic_status: data.monitoring_logistic_status,
          monitoring_shd_external_status: data.monitoring_shd_external_status,
          monitoring_change_management_status:
            data.monitoring_change_management_status,
          monitoring_floor_lrs_status: data.monitoring_floor_lrs_status,
          monitoring_dl_design_len_status: data.monitoring_dl_design_len_status,
        });
      });
    },

    onLoadDesign() {
      if (this.project.code === null) {
        this.$bvToast.toast("Please Choose Project First.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.loadDesign(this.project.code);
      }
    },
  },
  computed: {
    ...mapGetters(["getterChecklistDistributionDoc", "loadingDesign"]),

    currentData() {
      return this.getterChecklistDistributionDoc;
    },

    currentIdProject() {
      return this.project.code;
    },

    filteredDesign() {
      const station = this.dataFilter.station;
      const scope = this.dataFilter.scope;
      const title = this.dataFilter.title;

      return this.data.dld?.filter(
        (design) =>
          (station ? design.station === station : true) &&
          (scope ? design.scope === scope : true) &&
          (title
            ? design.title?.toLowerCase()?.includes(title?.toLowerCase())
            : true)
      );
    },
  },
  watch: {
    currentIdProject(value) {
      if (value === null) {
        this.cleanDocument();
      }
    },
  },
};
</script>
